import React, { ReactElement } from "react";
import { GatsbyPageProps, FixedSharpImageFile } from "@ymcansw-camping/common";
import { graphql } from "gatsby";
import OurCampLayout from "../../components/our-camp-layout";

type VirtualTourProps = GatsbyPageProps<{
  readonly bannerHeaderImage: FixedSharpImageFile;
}>;

export default function VirtualTour({
  data,
}: VirtualTourProps): ReactElement<VirtualTourProps> {
  return (
    <OurCampLayout
      meta={{
        title: "Virtual Tour",
        description:
          "Strap yourself in for the Virtual Tour of Camp Yarramundi. Set on 35 acres of natural bushland, it has the capacity to accommodate up to 260 people in a variety of cabins across the site.",
      }}
      bannerHeaderImage={data.bannerHeaderImage}
    >
      <div className="content-block">
        <h2>Strap yourself in for the Virtual Tour</h2>
      </div>
      <div className="virtual-tour-container">
        <iframe
          title="Virtual Tour"
          allowFullScreen
          frameBorder="0"
          height="600"
          src="https://www.google.com/maps/embed?pb=!1m0!3m2!1sen!2sau!4v1489203068615!6m8!1m7!1sF%3A-zgw7cemwqqE%2FWKfOstif7gI%2FAAAAAAAAIpw%2FYksNwf-K2ssmY3jdUzYM26aESSFaTtrkQCLIB!2m2!1d-33.61753057894306!2d150.680741071701!3f351.9708682287471!4f-4.206715851389774!5f0.7820865974627469"
          style={{ border: 0 }}
          width="100%"
        />
      </div>
    </OurCampLayout>
  );
}

export const query = graphql`
  query VirtualTourQuery {
    bannerHeaderImage: file(relativePath: { eq: "img/our-camp-bg.jpg" }) {
      ...GeneralPageLayout_bannerHeaderImage
    }
  }
`;
