import React, { ReactElement } from "react";
import {
  InThisSection,
  InThisSectionLink,
  FixedSharpImageFile,
  BannerHeader,
  Breadcrumbs,
} from "@ymcansw-camping/common";
import Layout, { LayoutProps } from "./layout";

type OurCampLayoutProps = LayoutProps & {
  readonly isIndex?: boolean;
  readonly bannerHeaderImage: FixedSharpImageFile;
};

export default function OurCampLayout({
  meta,
  isIndex,
  bannerHeaderImage,
  children,
}: OurCampLayoutProps): ReactElement<OurCampLayoutProps> {
  const breadcrumbs = isIndex
    ? [meta.title]
    : [{ to: "/our-camp/", label: "About us" }, meta.title];

  return (
    <Layout meta={meta}>
      <BannerHeader title={meta.title} image={bannerHeaderImage} />
      <div className="page-content md-pl3 lg-pl6">
        <div className="container">
          <div className="row">
            <div className="col-xs-12 col-md-9 col-md-push-3">
              <div className="page-content-header">
                <div className="row">
                  <div className="col-xs-12 col-md-7 col-lg-8">
                    <Breadcrumbs items={breadcrumbs} />
                  </div>
                </div>
              </div>
              <div className="content">{children}</div>
            </div>
            <div className="col-xs-12 col-md-3 col-md-pull-9">
              <InThisSection>
                <InThisSectionLink to="/our-camp/activities/">
                  Activities
                </InThisSectionLink>
                <InThisSectionLink to="/our-camp/our-team/">
                  Our Team
                </InThisSectionLink>
                <InThisSectionLink to="/our-camp/virtual-tour/">
                  Virtual Tour
                </InThisSectionLink>
                <InThisSectionLink to="/testimonials/">
                  Testimonials
                </InThisSectionLink>
                <InThisSectionLink to="/schools/learning-framework/">
                  Values
                </InThisSectionLink>
              </InThisSection>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
}
